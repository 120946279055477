<template>
  <dash-page-new
      :root="$t('Dashboard')"

      :subtitle="$store.state.settings.pageData.subtitle || $t('ProjectManger')"
      :title="$store.state.settings.pageData.title || $t('Projects')"
      :icon="$store.state.settings.pageData.icon || 'mdi-cogs'"
      :filters="$store.state.settings.pageData.filters || {}"
      :header-route="$store.state.settings.pageData.headerRoute || null"
      :filters-input="$store.state.settings.filtersData || []"
      :side-navigation="$store.state.settings.navigationSelect"
      @filter="$store.state.settings.filtersData = $event"
      navigation-route="project-manager"

  >

    <template #header_action.desktop>
      <div class="d-flex align-center">

        <ft-select
            v-if="($store.state.settings.pageData.headerAction || !!$store.state.settings.pageData.headerDropdownActions) && ($store.state.settings.pageData.headerActionCondition === null || $store.state.settings.pageData.headerActionCondition)"
            :items="$store.state.settings.pageData.headerDropdownActions || []">
          <ws-button
              @click="!$store.state.settings.pageData.headerDropdownActions ? $store.state.settings.pageData.headerAction() : () => {}"
              :label-raw="$store.state.settings.pageData.headerActionText"
              :click-stop="!$store.state.settings.pageData.headerDropdownActions"
              :icon="!!$store.state.settings.pageData.headerDropdownActions ? 'mdi-menu-down' : null"
          />
        </ft-select>

      </div>
    </template>

    <template #default>
      <router-view></router-view>
    </template>


  </dash-page-new>
</template>

<script>
export default {
  name: "BusinessProjectManager",
  data() {

  },
  computed : {
    navigationSelect() {
      return [
        { text : this.$t('Projects') , path : '' , value : '' , icon : 'mdi-file-tree'},
        { text : this.$t('projects.my_tasks') , path : 'tasks'    , value : 'tasks'    , icon : 'mdi-clipboard-check'},
        // { text : this.$t('meetings') , path : 'meetings' , value : 'meetings' , icon : 'mdi-cogs'},

      ]
    }
  },
  methods : {
    initPage() {
      this.$store.state.settings.navigationSelect = this.COPY(this.navigationSelect)
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>